import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { default as BsNav } from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import '../styles/index.css';

const MyNav = () => (
	<Navbar expand='md' style={{ background: 'rgba(55,76,94,0.93)', color: '#82b4df' }}>
		<div className='container'>
			<Navbar.Brand>
				<Link to='/'>
					<StaticImage src='../images/logo.png' alt='ASGSR logo' height={64} />
				</Link>
			</Navbar.Brand>
			<Navbar.Toggle aria-controls='main-nav' />
			<Navbar.Collapse id='main-nav' className='d-md-flex justify-content-end'>
				<BsNav>
					<Link to='/' className='nav-link' activeClassName='active'>
						Home
					</Link>

					<NavDropdown title='Learn'>
						<Link to='/learn' className='dropdown-item'>
							Getting Started
						</Link>
						<Link to='/learn/resources' className='dropdown-item'>
							Resources
						</Link>
						<Link to='/learn/opportunities' className='dropdown-item'>
							Opportunities
						</Link>
						<NavDropdown.Item
							href='https://youtube.com/channel/UCrJgI-D8GsNNfwlJSfuewpA'
							title='YouTube'
							target='_blank'
						>
							ASGSR Webinars
						</NavDropdown.Item>
					</NavDropdown>

					<Link to='/partners' className='nav-link' activeClassName='active'>
						Partners
					</Link>

					<Link to='/membership' className='nav-link' activeClassName='active'>
						Membership
					</Link>
					
					<a href='https://www.redbubble.com/people/asgsr/shop' className='nav-link' target='_blank'>
						Merchandise
					</a>

					<NavDropdown title='About'>
						<Link to='/about' className='dropdown-item'>
							About Us
						</Link>
						<Link to='/about/political-advocacy' className='dropdown-item'>
							Political Advocacy
						</Link>
						<Link to='/about/team' className='dropdown-item'>
							Meet the Team
						</Link>
						<Link to='/about/past-members' className='dropdown-item'>
							Past Board Members
						</Link>
						<NavDropdown.Item href='https://asgsr.org/' target='_blank'>
							ASGSR Website
						</NavDropdown.Item>
					</NavDropdown>
				</BsNav>
			</Navbar.Collapse>
		</div>
	</Navbar>
);

export default MyNav;
