import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import Footer from './Footer';
import Nav from './Nav';
import SEO from './SEO';

interface Props {
	children: any;
	description?: string;
	title?: string;
	image?: string;
	banner?: boolean;
}

const Layout = ({ children, banner, ...seo }: Props) => {
	const videoRef = useRef<HTMLVideoElement>(null);
	const [width, setWidth] = useState<number>(0);

	useEffect(() => {
		if (typeof window !== undefined) {
			setWidth(window.innerWidth);
			window.addEventListener('resize', () => setWidth(window.innerWidth));
		}

		if (videoRef && videoRef.current) {
			videoRef.current.playbackRate = 0.75;
		}
	}, [videoRef]);

	return (
		<Fragment>
			<SEO {...seo} />
			<Nav />
			{banner && (
				<video
					src={getSrc(width)}
					style={{ width: '100%' }}
					playsInline
					autoPlay
					muted
					ref={videoRef}
					about='ASGSR Students Intro Video'
				/>
			)}
			<Container>{children}</Container>
			<Footer />
		</Fragment>
	);
};

function getSrc(width: number) {
	if (width < 576) return '../intro/360p-vertical.mp4';
	if (width < 768) return '../intro/480p-vertical.mp4';
	if (width < 992) return '../intro/480p.mp4';
	if (width < 1200) return '../intro/720p.mp4';
	return '../intro/1080p.mp4';
}

export default Layout;
