import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
	title?: string;
	description?: string;
	image?: string;
}

interface QueryResults {
	site: {
		siteMetadata: {
			title: string;
			titleTemplate: string;
			description: string;
			url: string;
			twitterUsername: string;
		};
	};
}

const SEO = ({ description, image, title }: Props) => {
	const {
		site: { siteMetadata: metadata }
	} = useStaticQuery(graphql`
		query SEO {
			site {
				siteMetadata {
					title
					titleTemplate
					description
					url: siteUrl
					twitterUsername
				}
			}
		}
	`) as QueryResults;

	const seo = {
		title: title || metadata.title,
		description: description || metadata.description,
		image: image
	};

	return (
		<Helmet title={seo.title} titleTemplate={metadata.titleTemplate}>
			<meta name='description' content={seo.description} />
			{seo.image && <meta name='image' content={seo.image} />}

			<meta property='og:title' content={seo.title} />
			<meta property='og:description' content={seo.description} />
			{seo.image && <meta property='og:image' content={seo.image} />}

			<meta name='twitter:card' content='summary_large_image' />
			<meta name='twitter:title' content={seo.title} />
			<meta name='twitter:description' content={seo.description} />
			{seo.image && <meta name='twitter:image' content={seo.image} />}

			<link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
			<link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
			<link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
			<link rel='manifest' href='/site.webmanifest' />
		</Helmet>
	);
};

export default SEO;
